"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.characterIds = void 0;
exports.characterIds = {
    hodol: {
        plate: [
            { serverName: 'cenarius', characterName: '죽음의기사호돌' },
            { serverName: 'zuljin', characterName: '죽음의기사호돌' },
            { serverName: 'cenarius', characterName: '성기사호돌' },
            { serverName: 'zuljin', characterName: '성기사호돌' },
            { serverName: 'zuljin', characterName: '전사호돌' },
        ],
        mail: [
            { serverName: 'cenarius', characterName: '주술사호돌' },
            { serverName: 'cenarius', characterName: '기원사호돌' },
            { serverName: 'zuljin', characterName: '사냥꾼호돌' },
        ],
        leather: [
            { serverName: 'cenarius', characterName: '드루이드호돌' },
            { serverName: 'zuljin', characterName: '도적호돌' },
            { serverName: 'zuljin', characterName: '수도사호돌' },
            { serverName: 'zuljin', characterName: '악마사냥꾼호돌' },
        ],
        cloth: [
            { serverName: 'cenarius', characterName: '마법사호돌' },
            { serverName: 'cenarius', characterName: '사제호돌' },
            { serverName: 'cenarius', characterName: '흑마법사호돌' },
            { serverName: 'zuljin', characterName: '흑마법사호돌' },
        ],
    },
    nick210: {
        plate: [{ serverName: 'hyjal', characterName: '가물치회' }],
        mail: [],
        leather: [{ serverName: 'hyjal', characterName: 'sophangel' }],
        cloth: [{ serverName: 'hyjal', characterName: 'sophnick' }],
    },
};
