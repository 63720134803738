"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineViews = exports.routes = void 0;
const home_page_1 = require("../main/home-page");
const list_1 = require("../wow/list");
exports.routes = [
    {
        path: '/web',
        tag: 'home-page',
        pathParamNames: [],
        queryParamNames: [],
    },
    {
        path: '/web/wow/:player',
        tag: 'wow-list',
        pathParamNames: ['player'],
        queryParamNames: [],
    },
];
const defineViews = () => {
    (0, list_1.defineWowList)('wow-list');
    (0, home_page_1.defineHomePage)('home-page');
};
exports.defineViews = defineViews;
