"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineWowGear = exports.WowGear = void 0;
const lib_es2015_1 = require("@hodol/web-component-helper/lib-es2015");
const lib_es2015_2 = require("@hodol/dom-helper/lib-es2015");
class WowGear extends HTMLElement {
    constructor(gear) {
        super();
        this.getInternalBorderColor = () => {
            switch (this.gear.quality) {
                case '하급':
                    return '#9d9d9d';
                case '일반':
                    return '#ffffff';
                case '고급':
                    return '#1eff00';
                case '희귀':
                    return '#0070ff';
                case '영웅':
                    return '#a335ee';
                case '전설':
                    return '#ff8000';
                case '계승품':
                    return '#00ccff';
                case '유물':
                    return '#e6cc80';
            }
            return '';
        };
        this.style.setProperty('display', 'block');
        this.gear = gear;
        this.innerHTML = `
    <div class="internal-border">
      <div class="text-content">${this.gear.level}</div>
    </div>`;
        this.style.setProperty('background-image', `url('${this.gear.icon_url}')`);
        (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'div.internal-border').style.setProperty('border-color', this.getInternalBorderColor());
        const levelBox = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'div.text-content');
        levelBox.classList.remove('text-outline-white-black', 'text-outline-blue-white', 'text-outline-red-white');
        levelBox.classList.add(gear.level >= 395 ? 'text-outline-white-black' : gear.level >= 385 && gear.level < 395 ? 'text-outline-blue-white' : 'text-outline-red-white');
    }
}
exports.WowGear = WowGear;
const defineWowGear = (name) => (0, lib_es2015_1.defineIfUndefined)(name, WowGear);
exports.defineWowGear = defineWowGear;
