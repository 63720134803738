"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineWowListItem = exports.WowListItem = void 0;
const gear_1 = require("./gear");
const lib_es2015_1 = require("@hodol/web-component-helper/lib-es2015");
const lib_es2015_2 = require("@hodol/dom-helper/lib-es2015");
const parts = [
    'head',
    'neck',
    'shoulder',
    'chest',
    'back',
    'waist',
    'wrist',
    'hand',
    'leg',
    'foot',
    'weapon',
    'offhand',
    'left_finger',
    'right_finger',
    'left_trinket',
    'right_trinket',
];
class WowListItem extends HTMLElement {
    constructor(characterInfo) {
        super();
        this.characterInfo = characterInfo;
        this.style.setProperty('display', 'flex');
        this.innerHTML = `
    <div class="character-id">
      <div class="text-content text-outline-white-black">
        ${this.characterInfo.name} (${characterInfo.faction === '얼라이언스' ? '얼라' : '호드'})
      </div>
    </div>`;
        const characterProfile = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'div.character-id');
        characterProfile.style.setProperty('background-image', `url('${this.characterInfo.avatar_url}')`);
        parts.forEach(part => {
            this.appendChild(new gear_1.WowGear(this.characterInfo.gear_set[part]));
        });
    }
}
exports.WowListItem = WowListItem;
const defineWowListItem = (name) => {
    (0, gear_1.defineWowGear)('wow-gear');
    (0, lib_es2015_1.defineIfUndefined)(name, WowListItem);
};
exports.defineWowListItem = defineWowListItem;
