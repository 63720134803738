"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineWowList = exports.WowList = void 0;
const apis_1 = require("./apis");
const chracterIds_1 = require("./chracterIds");
const lib_es2015_1 = require("@hodol/dom-helper/lib-es2015");
const list_item_1 = require("./list-item");
const lib_es2015_2 = require("@hodol/web-component-helper/lib-es2015");
class WowList extends HTMLElement {
    constructor() {
        super();
        this.apis = new apis_1.default();
        this.plateCharacterIds = [];
        this.mailCharacterIds = [];
        this.leatherCharacterIds = [];
        this.clothCharacterIds = [];
        this.plateCharacterInfo = [];
        this.mailCharacterInfo = [];
        this.leatherCharacterInfo = [];
        this.clothCharacterInfo = [];
        this.fetchItems = () => __awaiter(this, void 0, void 0, function* () {
            this.plateCharacterInfo.splice(0, this.plateCharacterInfo.length);
            for (const id of this.plateCharacterIds) {
                this.innerHTML = this.innerHTML + `<br>loading ${id.characterName} (${id.serverName})`;
                const apiResp = yield this.apis.getCharacter(id.serverName, encodeURI(id.characterName));
                if (apiResp === null) {
                    continue;
                }
                this.plateCharacterInfo.push(apiResp);
            }
            this.mailCharacterInfo.splice(0, this.mailCharacterInfo.length);
            for (const id of this.mailCharacterIds) {
                this.innerHTML = this.innerHTML + `<br>loading ${id.characterName} (${id.serverName})`;
                const apiResp = yield this.apis.getCharacter(id.serverName, encodeURI(id.characterName));
                if (apiResp === null) {
                    continue;
                }
                this.mailCharacterInfo.push(apiResp);
            }
            this.leatherCharacterInfo.splice(0, this.leatherCharacterInfo.length);
            for (const id of this.leatherCharacterIds) {
                this.innerHTML = this.innerHTML + `<br>loading ${id.characterName} (${id.serverName})`;
                const apiResp = yield this.apis.getCharacter(id.serverName, encodeURI(id.characterName));
                if (apiResp === null) {
                    continue;
                }
                this.leatherCharacterInfo.push(apiResp);
            }
            this.clothCharacterInfo.splice(0, this.clothCharacterInfo.length);
            for (const id of this.clothCharacterIds) {
                this.innerHTML = this.innerHTML + `<br>loading ${id.characterName} (${id.serverName})`;
                const apiResp = yield this.apis.getCharacter(id.serverName, encodeURI(id.characterName));
                if (apiResp === null) {
                    continue;
                }
                this.clothCharacterInfo.push(apiResp);
            }
        });
        this.style.setProperty('display', 'block');
        this.innerHTML = 'Loading...';
    }
    connectedCallback() {
        return __awaiter(this, void 0, void 0, function* () {
            const player = (0, lib_es2015_1.getAttributeOrThrowError)(this, 'player');
            this.plateCharacterIds.push(...chracterIds_1.characterIds[player].plate);
            this.mailCharacterIds.push(...chracterIds_1.characterIds[player].mail);
            this.leatherCharacterIds.push(...chracterIds_1.characterIds[player].leather);
            this.clothCharacterIds.push(...chracterIds_1.characterIds[player].cloth);
            yield this.fetchItems();
            this.innerHTML = `
    <h2>판금</h2>
    <div class="list-item-container plate"></div>
    <h2>사슬</h2>
    <div class="list-item-container mail"></div>
    <h2>가죽</h2>
    <div class="list-item-container leather"></div>
    <h2>천</h2>
    <div class="list-item-container cloth"></div>
    `;
            const plateItemContainer = (0, lib_es2015_1.getQuerySelectOrThrowError)(this, 'div.list-item-container.plate');
            this.plateCharacterInfo.forEach(info => {
                plateItemContainer.appendChild(new list_item_1.WowListItem(info));
            });
            const mailItemContainer = (0, lib_es2015_1.getQuerySelectOrThrowError)(this, 'div.list-item-container.mail');
            this.mailCharacterInfo.forEach(info => {
                mailItemContainer.appendChild(new list_item_1.WowListItem(info));
            });
            const leatherItemContainer = (0, lib_es2015_1.getQuerySelectOrThrowError)(this, 'div.list-item-container.leather');
            this.leatherCharacterInfo.forEach(info => {
                leatherItemContainer.appendChild(new list_item_1.WowListItem(info));
            });
            const clothItemContainer = (0, lib_es2015_1.getQuerySelectOrThrowError)(this, 'div.list-item-container.cloth');
            this.clothCharacterInfo.forEach(info => {
                clothItemContainer.appendChild(new list_item_1.WowListItem(info));
            });
        });
    }
}
exports.WowList = WowList;
const defineWowList = (name) => {
    (0, list_item_1.defineWowListItem)('wow-list-item');
    (0, lib_es2015_2.defineIfUndefined)(name, WowList);
};
exports.defineWowList = defineWowList;
